// extracted by mini-css-extract-plugin
export var close = "_MainMenu-module--close--4594b";
export var container = "_MainMenu-module--container--7a2b9";
export var hamburger = "_MainMenu-module--hamburger--9c585";
export var item = "_MainMenu-module--item--ee5e6";
export var items = "_MainMenu-module--items--d6d00";
export var langs = "_MainMenu-module--langs--333a4";
export var logo = "_MainMenu-module--logo--f075c";
export var logoMobile = "_MainMenu-module--logo-mobile--9f774";
export var mainMenu = "_MainMenu-module--main-menu--a3448";
export var mobileMenu = "_MainMenu-module--mobile-menu--a241f";
export var mobileMenuBackground = "_MainMenu-module--mobile-menu-background--8048a";
export var mobileMenuShadow = "_MainMenu-module--mobile-menu-shadow--566c8";
export var pages = "_MainMenu-module--pages--d19ac";
export var subItem = "_MainMenu-module--sub-item--7b7f3";